* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}
body {
  line-height: 1.4;
  overflow-x: hidden;
  background-color: var(--myWhite);
  font-family: arial, sans-serif;
  font-size: 16px;
}
ol,
ul {
  list-style: none;
}

/* Global styles */
:root {
  --primary: #034366;
  --secondary: hsl(108, 44%, 50%);
  --transSecondary: hsla(108, 44%, 50%, 0.699);
  --myWhite: #f6f6f6;
  --transWhite: #f6f6f699;
  --tertiary: #ffdb15;
}
.btn-tertiary {
  color: var(--primary);
  transition: all 0.45s ease;
}
.btn-tertiary:hover {
  background: var(--primary);
  color: var(--tertiary);
  border: 1px solid var(--primary);
}

h1,
h2,
h3,
h4,
h6 {
  font-family: Garamond, Georgia, serif;
}

section,
.container,
.container-fluid {
  margin: 0 auto;
  max-width: 1600px;
}

/* Buttons */
#faqBtn {
  cursor: pointer;
}

#faqBtn:hover {
  text-decoration: 2px solid underline;
  text-underline-offset: 4px;
}

.btn {
  font-family: Garamond, Georgia, serif;
  font-size: 18px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.054);
}
/* Navbar */

.navbar {
  background-color: var(--transWhite);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.074);
}
.nav-link,
.nav-link:visited {
  color: var(--primary);
  transition: all ease 0.4s;
}

.nav-link:hover:not(.active) {
  color: var(--secondary);
  text-decoration: 2px underline solid var(--secondary);
  text-underline-offset: 3px;
}
.navbar-nav .nav-link.active {
  color: var(--secondary);
  font-weight: bolder;
  text-underline-offset: 3px;
}
.navbar-scrolled .nav-link,
.nav-link:visited {
  color: var(--myWhite);
}

#quote-link:hover {
  text-decoration: 1px solid underline;
  text-underline-offset: 3px;
}

@media (max-width: 768px) {
  .navbar {
    background: none;
    box-shadow: none;
  }
  .navbar-scrolled {
    background: none;
    box-shadow: none;
  }
  .navbar-toggler {
    z-index: 2;
  }
  .navbar-toggler:focus {
    border: 0;
    outline: none;
    box-shadow: none;
  }
  .navbar-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: var(--primary);
    z-index: 1;
  }
  .nav-link,
  .nav-link:visited {
    color: var(--myWhite);
  }
}
@media (min-width: 768px) and (max-width: 769px) {
  .navbar-nav {
    justify-content: end;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: var(--transWhite);
    color: var(--primary);
    z-index: 1;
  }
  .nav-link,
  .nav-link:visited {
    color: var(--primary);
  }
}
@media (min-width: 769px) {
  .navbar-scrolled {
    background-color: var(--transWhite);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .nav-link,
  .nav-link:visited {
    color: var(--primary);
  }
}

/* HeroSectionWithCta */
#HeroSectionWithCta {
  min-height: 100vh;
  width: 100vw;
  margin: 0 auto;
  text-align: center;
  background-image: url(../src/images/yard.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
#HeroSectionWithCta .cta-details {
  margin: 0 auto;
  background: linear-gradient(transparent, #000000d3, #000000d3);
}

#HeroSectionWithCta .cta-details p {
  margin: 0 auto;
}

/* About Section */
.about-img {
  background-image: url(../src/images/window-cleaning.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 50%; */
  height: 500px;
}
/* reviews-carousel */
#reviews-carousel {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  max-width: 95%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pageBreak {
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
}
#yardPageBreak {
  background-image: url(../src/images/cleaners.jpg);
  display: flex;
  align-items: center;
  justify-content: center;
}

#windowPageBreak {
  background-image: url(../src/images/disinfect.jpg);
  background-position-y: -150px;
}

/* Services */
.service-icons .col-3 {
  font-size: 6rem;
  color: var(--primary);
  /* cursor: pointer; */
}
@media (max-width: 575.98px) {
  .service-icons .col-3 {
    font-size: 2rem;
  }
}
@media (max-width: 767.98px) {
  .service-icons .col-3 {
    font-size: 4rem;
  }
}

/* ServiceTabs */

/* Promos sections */
.BnbAd-img {
  background-image: url(../src/images/bnb.png);
}
.PromoRight-img {
  background-image: url(../src/images/momnchild.png);
}
.BnbAd-img,
.PromoRight-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 500px;
  width: 40%;
}

@media (max-width: 767.98px) {
  .BnbAd-img,
  .PromoRight-img {
    background-attachment: fixed;
    width: 100%;
  }
}

.quoteLine {
  text-decoration: underline var(--myWhite);
  text-underline-offset: 5px;
}
.quoteLine:hover {
  text-decoration: underline 3px var(--tertiary);
}

/* Contact Us Section */
#contact-details a {
  display: flex;
  text-decoration: none;
  justify-content: space-evenly;
  align-items: center;
  color: #000;
  max-width: 70%;
  margin: 0 auto 1rem;
  padding: 0;
  /* flex-wrap: wrap; */
}

#contact-details a i {
  font-size: 1.2rem;
  background: var(--secondary);
  color: var(--myWhite);
  border-radius: 50%;
  padding: 0.7rem;
  margin-right: 1rem;
}

#contact-details a div {
  width: 200px;
  text-align: left;
  padding: 0;
}
#contact-details a div h4 {
  font-weight: bolder;
  color: var(--primary);
  font-size: 1.4rem;
}
#contact-details a div p {
  padding-bottom: 0;
  margin-bottom: 0;
  /* text-wrap: wrap; */
}

.form {
  max-width: 470px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.form input,
.form textarea {
  display: block;
  width: 100%;
  border-radius: 3px;
  border: none;
  outline: 0;
  padding: 10px;
  margin-bottom: 0.7rem;
  background-color: var(--myWhite);
}
.form input[type="submit"] {
  margin: 1.2rem auto 0;
  background-color: var(--tertiary);
  color: var(--primary);
  transition: all ease 0.45s;
}
.form input[type="submit"]:hover {
  background-color: transparent;
  outline: 1px solid var(--secondary);
  color: var(--secondary);
}

@media (max-width: 767.98px) {
  .form {
    min-width: 100%;
  }
}

/* FAQs */
@media (min-width: 768px) {
  #faqs {
    max-width: 80%;
  }
}
.accordion-flush {
  padding: 1.2rem;
  border-radius: 5px;
  background-color: var(--primary);
  text-align: left;
}
.accordion-item {
  background-color: var(--primary);
  padding: 0.5rem 1rem;
  color: var(--myWhite);
}
.accordion-body {
  padding: 0.6rem 0.5rem 1.2rem;
}
.accordion-header {
  margin-bottom: 0.6rem;
  border-radius: 0 0 10px 10px;
}

.accordion-button {
  font-weight: bolder;
  font-size: 1.2rem;
  color: var(--myWhite);
  background-color: var(--primary);
  border-radius: 8px;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button {
  border-radius: 5px;
  background-color: var(--secondary);
  box-shadow: none;
}
.accordion-flush
  > .accordion-item
  > .accordion-header
  .accordion-button.collapsed {
  border-radius: 5px;
  background-color: var(--primary);
  box-shadow: none;
}
.accordion-button::after {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"%3E%3Cpolyline points="6 9 12 15 18 9"%3E%3C/polyline%3E%3C/svg%3E');
}
/* .accordion-button:not(.collapsed)::after {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"%3E%3Cpolyline points="18 15 12 9 6 15"%3E%3C/polyline%3E%3C/svg%3E');
} */

.accordion {
  --bs-accordion-btn-icon: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"%3E%3Cpolyline points="6 9 12 15 18 9"%3E%3C/polyline%3E%3C/svg%3E');
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"%3E%3Cpolyline points="6 9 12 15 18 9"%3E%3C/polyline%3E%3C/svg%3E');
}

/* Quote Form */
#quotePg {
  background: url("./images/quote.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  inset: 0 0;
  position: relative;
}
#quote-form {
  background: var(--primary);
}
#quote-form fieldset {
  border-radius: 8px;
  border: 0.5px solid var(--transWhite);
  overflow: hidden;
}
#quote-form fieldset legend {
  display: inline-block;
  font-family: Garamond, Georgia, serif;
  color: var(--secondary);
  text-align: left;
  text-decoration: 1px solid underline;
  text-underline-offset: 3px;
}
#quote-form .heading {
  font-size: 16px;
  color: var(--myWhite);
  font-family: monospace;
  text-transform: capitalize;
}
#quote-form .sentence {
  text-transform: none;
}

@media (min-width: 576px) {
  #quote-form {
    border-radius: 8px;
    max-width: 80%;
    width: fit-content;
  }
}

#quote-form .form-control {
  border-radius: 3px;
  border: none;
  padding: 4px 1rem;
  transition: all ease 0.45s;
  color: #000;
  background-color: var(--transWhite);
}

#quote-form input:hover,
#quote-form textarea:hover {
  background-color: var(--secondary);
  color: var(--myWhite);
}

#quote-form input:focus,
#quote-form textarea:focus {
  background: var(--secondary);
  color: var(--myWhite);
  border: 2px solid var(--transWhite);
}

#quote-form label {
  font-size: 14px;
  color: var(--myWhite);
  font-family: monospace;
  margin-bottom: 0.3rem;
  padding-left: 0.5rem;
  text-transform: capitalize;
}
#quote-form input[radio] {
  font-size: 18px;
  padding: 1rem;
  background: red;
  outline: yellow;
  border: 5px solid green;
}
