// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
$primary: #034366;
$secondary: #5fb748;

// import the functions and variables to be able to add custom colors to variables map
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$custom-theme-colors: (
  "myWhite": #f6f6f6,
  "transWhite": #f6f6f699,
  "tertiary": #ffdb15,
);

$theme-colors: map-merge($custom-theme-colors, $theme-colors);

@import "../../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here
